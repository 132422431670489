import "./index.scss";
import Swiper, { Navigation } from 'swiper';
import Cookies from 'js-cookie'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

//Menu
const navContainer = document.getElementById("nav-container");
const navContainerClassList = navContainer.classList;
const menuBtn = document.getElementById("toggle-menu");
const menuVisibilityClass = "visible";

menuBtn.addEventListener("click", () => {
    if (navContainerClassList.contains(menuVisibilityClass)) {
        navContainerClassList.remove("visible");
        // enableBodyScroll(navContainer);
    } else {
        navContainerClassList.add("visible");
        // disableBodyScroll(navContainer);
    }

})

//Consent cookie
const cookieName = "consentRead";

const consentContainerClassList = document.querySelector("#user-consent").classList;
const consentVisibilityClass = "visible";

console.log(Cookies.get(cookieName));

if (!Cookies.get(cookieName))
    consentContainerClassList.add(consentVisibilityClass);

function consent() {
    Cookies.set(cookieName, true, { expires: 730 });
    consentContainerClassList.remove(consentVisibilityClass);
}

window.consent = consent;

//Swiper carousels
const baseSwiperSettings = {
    modules: [Navigation],
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    spaceBetween: 30,
    loop: true,
}

new Swiper(".trustedUsSwiper", {
    ...baseSwiperSettings,
    slidesPerView: "auto",
    autoplay: {
        delay: 3000,
    },
});

new Swiper(".caseStudiesSwiper", {
    ...baseSwiperSettings,
    slidesPerView: 1,
    breakpoints: {
        500: {
            slidesPerView: "auto",
        },
        1000: {
            slidesPerView: 3,
            centeredSlides: true,
        },
    },
});

new Swiper(".opinionsSwiper", baseSwiperSettings);

//Animated scrolling on nav anchors
const scrollLinks = document.querySelectorAll("nav a");
scrollLinks.forEach(link => {
    link.addEventListener("click", () => {
        window.scrollTo({
            top: 150,
            behavior: "smooth"
        });

        navContainerClassList.remove("visible");
        // enableBodyScroll(navContainer);
    });
})

//Form AJAX
document.addEventListener('submit', (e) => {
    fbq('track', "Lead");

    const form = e.target;
    fetch(form.action, {
        method: form.method,
        body: new FormData(form),
    })
        .then((res) => res.text())
        .then((text) => new DOMParser().parseFromString(text, 'text/html'))
        .then((doc) => {
            const result = document.createElement('div');
            result.innerHTML = doc.body.innerHTML;
            result.tabIndex = -1;
            form.parentNode.replaceChild(result, form);
            result.focus();
        });

    e.preventDefault();
});